import React from "react";

export const Pause = () => {
	return (
		<svg
			width="101"
			height="100"
			viewBox="0 0 101 100"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M50.8447 0C23.2447 0 0.844727 22.4 0.844727 50C0.844727 77.6 23.2447 100 50.8447 100C78.4447 100 100.845 77.6 100.845 50C100.845 22.4 78.4947 0 50.8447 0ZM44.5947 65.15C44.5947 67.55 43.5947 68.5 41.0447 68.5H34.5447C31.9947 68.5 30.9947 67.55 30.9947 65.15V34.85C30.9947 32.45 31.9947 31.5 34.5447 31.5H40.9947C43.5447 31.5 44.5447 32.45 44.5447 34.85V65.15H44.5947ZM70.9947 65.15C70.9947 67.55 69.9947 68.5 67.4447 68.5H60.9947C58.4447 68.5 57.4447 67.55 57.4447 65.15V34.85C57.4447 32.45 58.4447 31.5 60.9947 31.5H67.4447C69.9947 31.5 70.9947 32.45 70.9947 34.85V65.15Z"
				fill="url(#paint0_linear_9_1140)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_9_1140"
					x1="90.2035"
					y1="8.19925"
					x2="46.9247"
					y2="88.9682"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#67C3F3" />
					<stop offset="1" stop-color="#5A98F2" />
				</linearGradient>
			</defs>
		</svg>
	);
};
