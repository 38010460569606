import React from "react";

export const Stop = () => {
	return (
		<svg
			width="101"
			height="100"
			viewBox="0 0 101 100"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M50.8447 0C23.2447 0 0.844727 22.4 0.844727 50C0.844727 77.6 23.2447 100 50.8447 100C78.4447 100 100.845 77.6 100.845 50C100.845 22.4 78.4947 0 50.8447 0ZM72.1447 56.15C72.1447 64.45 65.4447 71.15 57.1447 71.15H44.8447C36.5447 71.15 29.8447 64.45 29.8447 56.15V43.85C29.8447 35.55 36.5447 28.85 44.8447 28.85H57.1447C65.4447 28.85 72.1447 35.55 72.1447 43.85V56.15Z"
				fill="url(#paint0_linear_9_1148)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_9_1148"
					x1="90.2035"
					y1="8.19925"
					x2="46.9247"
					y2="88.9682"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#67C3F3" />
					<stop offset="1" stop-color="#5A98F2" />
				</linearGradient>
			</defs>
		</svg>
	);
};
