import React from "react";
import Background from "./Background";
import { useNavigate, redirect } from "react-router-dom";
import "./Button.css";
import "./Main.css";
import "./Background.css";
import { useEffect } from "react";

const Main = ({ isAuthenticated }) => {
	let navigate = useNavigate();

	const handleService = (e) => {
		console.log("click");
		e.preventDefault();
		navigate("/recording");
	};

	useEffect(() => {
		if (!isAuthenticated) {
			navigate("/login");
		}
	}, [isAuthenticated]);

	return (
		<div className="main-container">
			<div className="Frame_Frame">
				<h1>What's Today's Consultation?</h1>
				<p>
					Please inform us of the type of appointment you require, and we will ensure
					that the appropriate tools <br /> and resources are made available to
					optimize your session experience.
				</p>
				<button className="btn-face-to-face" onClick={handleService}>
					Face to Face
				</button>
			</div>
			<Background />
		</div>
	);
};

export default Main;
