import "./App.css";
import Main from "./Components/Main";
import Recording from "./Components/Recording";
import Login from "./Components/Login";
import { useState } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	redirect,
} from "react-router-dom";

// const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => {
// 	return <Route {...rest} render={(props) => <Component {...props} />} />;
// };

const App = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	return (
		<div className="App">
			<Router>
				<Routes>
					<Route
						exact
						path="/login"
						element={
							<Login
								isAuthenticated={isAuthenticated}
								setAuthenticated={setIsAuthenticated}
							/>
						}
					/>
					<Route
						exact
						path="/"
						element={<Main isAuthenticated={isAuthenticated} />}
					/>
					<Route
						exact
						path="/recording"
						element={<Recording isAuthenticated={isAuthenticated} />}
					/>
					{/* <Route
						exact
						path="/transcription"
						element={<RecordingResult recordData={recordData} />}
					/>
					<Route exact path="/summary" element={<Summary />} /> */}
				</Routes>
			</Router>
		</div>
	);
};

export default App;
