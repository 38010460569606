import React from "react";

export const Play = () => {
	return (
		<svg
			width="101"
			height="100"
			viewBox="0 0 101 100"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M50.8447 0C23.2447 0 0.844727 22.4 0.844727 50C0.844727 77.6 23.2447 100 50.8447 100C78.4447 100 100.845 77.6 100.845 50C100.845 22.4 78.4947 0 50.8447 0ZM65.8447 61.15L51.3447 69.5C49.5992 70.5172 47.615 71.0532 45.5947 71.0532C43.5744 71.0532 41.5903 70.5172 39.8447 69.5C38.0853 68.4931 36.6255 67.0363 35.615 65.279C34.6045 63.5216 34.0798 61.5271 34.0947 59.5V42.75C34.0947 38.6 36.2447 34.85 39.8447 32.75C43.4447 30.65 47.7447 30.65 51.3947 32.75L65.8947 41.1C69.4947 43.2 71.6447 46.9 71.6447 51.1C71.6447 55.3 69.4947 59.05 65.8447 61.15Z"
				fill="url(#paint0_linear_9_1146)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_9_1146"
					x1="90.2035"
					y1="8.19925"
					x2="46.9247"
					y2="88.9682"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#67C3F3" />
					<stop offset="1" stop-color="#5A98F2" />
				</linearGradient>
			</defs>
		</svg>
	);
};
