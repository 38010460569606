import React from "react";
import "./Background.css";
// import ImgAsset from '../Assets'

const Background = () => {
	// return <img className='Vector' src={ImgAsset.Frame_Vector} alt="backfloat" />
	return null;
};

export default Background;
