import React from "react";

export const Cancel = () => {
	return (
		<svg
			width="100"
			height="100"
			viewBox="0 0 100 100"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M50 0C22.45 0 0 22.45 0 50C0 77.55 22.45 100 50 100C77.55 100 100 77.55 100 50C100 22.45 77.55 0 50 0ZM66.8 61.5C68.25 62.95 68.25 65.35 66.8 66.8C66.05 67.55 65.1 67.9 64.15 67.9C63.2 67.9 62.25 67.55 61.5 66.8L50 55.3L38.5 66.8C37.75 67.55 36.8 67.9 35.85 67.9C34.9 67.9 33.95 67.55 33.2 66.8C32.5026 66.0943 32.1115 65.1422 32.1115 64.15C32.1115 63.1578 32.5026 62.2057 33.2 61.5L44.7 50L33.2 38.5C32.5026 37.7943 32.1115 36.8422 32.1115 35.85C32.1115 34.8578 32.5026 33.9057 33.2 33.2C34.65 31.75 37.05 31.75 38.5 33.2L50 44.7L61.5 33.2C62.95 31.75 65.35 31.75 66.8 33.2C68.25 34.65 68.25 37.05 66.8 38.5L55.3 50L66.8 61.5Z"
				fill="url(#paint0_linear_9_1588)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_9_1588"
					x1="89.3588"
					y1="8.19925"
					x2="46.08"
					y2="88.9682"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#67C3F3" />
					<stop offset="1" stop-color="#5A98F2" />
				</linearGradient>
			</defs>
		</svg>
	);
};
