import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.css"; // Import CSS module

const Login = ({ isAuthenticated, setAuthenticated }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		// Validate the entered credentials here
		const isValid =
			email === "admin@medtool.ai" && password === "MedTool@Admin123";
		if (isValid) {
			setAuthenticated(true);
			navigate("/");
		}
	};

	useEffect(() => {
		if (isAuthenticated) {
			navigate("/");
		}
	}, [isAuthenticated]);

	return (
		<div className={styles.loginContainer}>
			<form className={styles.loginForm} onSubmit={handleSubmit}>
				<h2>Login</h2>
				<input
					className={styles.inputField}
					type="email"
					placeholder="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<br />
				<input
					className={styles.inputField}
					type="password"
					placeholder="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<br />
				<button className={styles.submitButton} type="submit">
					Login
				</button>
			</form>
		</div>
	);
};

export default Login;
