import React from "react";

export const Start = () => {
	return (
		<svg
			width="31"
			height="30"
			viewBox="0 0 31 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.4544 0.416504C7.40443 0.416504 0.871094 6.94984 0.871094 14.9998C0.871094 23.0498 7.40443 29.5832 15.4544 29.5832C23.5044 29.5832 30.0378 23.0498 30.0378 14.9998C30.0378 6.94984 23.519 0.416504 15.4544 0.416504ZM15.4982 21.1686C12.0857 21.1686 9.32943 18.4123 9.32943 14.9998C9.32943 11.5873 12.0857 8.83109 15.4982 8.83109C18.9107 8.83109 21.6669 11.5873 21.6669 14.9998C21.6669 18.4123 18.9107 21.1686 15.4982 21.1686Z"
				fill="#FAFAFA"
			/>
		</svg>
	);
};
